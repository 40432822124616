import { createHashHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { routerMiddleware } from 'connected-react-router'
import reducers from './reducers'
// eslint-disable-next-line import/no-cycle
import sagas from './sagas'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
}

export const history = createHashHistory()

const persistedReducer = persistReducer(persistConfig, reducers(history))
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}

export const store = createStore(persistedReducer, compose(applyMiddleware(...middlewares)))

export const persistor = persistStore(store)

sagaMiddleware.run(sagas)
