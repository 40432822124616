import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import store from 'store';
import { history } from 'index';
import * as jwt from 'services/jwt';
import actions from './actions';

const mapAuthProviders = {
  jwt: {
    login: jwt.login,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
};

export function* LOGIN({ payload }) {
  const { phone, password, userType } = payload;
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const { authProvider: autProviderName } = yield select(state => state.settings);
  const { data } = yield call(mapAuthProviders[autProviderName].login, phone, password, userType);
  if (data.success) {
    const { id, name, surname, userType } = data.user;
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name: name,
        namesurname: `${name} ${surname}`,
        role: userType,
        authorized: true,
      },
    });
    yield history.push('/');
    notification.success({
      message: 'İşlem Başarılı',
      description: 'Başarılı bir şekilde sisteme giriş yaptınız.',
    });
  } else {
    notification.warning({
      message: 'İşlem Başarısız',
      description: data.message,
    });
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  try {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    });
    const { authProvider } = yield select(state => state.settings);
    const response = yield call(mapAuthProviders[authProvider].currentAccount);
    if (response?.success) {
      const { id, name, surname, userType } = response;
      yield put({
        type: 'user/SET_STATE',
        payload: {
          id,
          name: name,
          namesurname: `${name} ${surname}`,
          role: userType,
          authorized: true,
        },
      });
    } else {
      store.remove('accessToken');
      yield history.push('/auth/login');
    }
  } catch (e) {
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOGOUT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  });
  localStorage.removeItem('accessToken');
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ]);
}
