export default async function getMenuData() {
  return [
    {
      title: 'Kontrol Paneli',
      key: 'dashboard',
      icon: 'fe fe-airplay',
      url: '/dashboard',
      roles: [1, 2],
    },
    {
      title: 'Personeller',
      key: 'employees',
      icon: 'fe fe-users',
      url: '/employees',
      roles: [1, 2],
    },
    {
      title: 'Hedefler',
      key: 'goals',
      icon: 'fe fe-users',
      url: '/goals',
      roles: [1],
    },
    {
      title: 'Puantaj',
      key: 'puantaj',
      icon: 'fe fe-users',
      url: '/puantaj',
      roles: [1],
    },
    {
      title: 'Ekip',
      key: 'teams',
      icon: 'fe fe-users',
      url: '/teams',
      roles: [1, 2],
    },
    {
      title: 'Müşteriler',
      key: 'customers',
      icon: 'fe fe-users',
      url: '/customers',
      roles: [1, 2],
    },
    {
      title: 'Ziyaretler',
      key: 'visits',
      icon: 'fe fe-calendar',
      url: '/visits',
      roles: [1, 2],
    },
    {
      title: 'Randevular',
      key: 'appointments',
      icon: 'fe fe-calendar',
      url: '/appointments',
      roles: [1, 2],
    },
    {
      title: 'Onay Bekleyen Satışlar',
      key: 'waiting-sales',
      icon: 'fe fe-info',
      url: '/waiting-sales',
      roles: [1, 2],
    },
    {
      title: 'Satışlar',
      key: 'sales',
      icon: 'fe fe-check',
      url: '/sales',
      roles: [1, 2],
    },
    {
      title: 'Muhasebe',
      key: 'accounting',
      icon: 'fe fe-credit-card',
      url: '/accounting',
      roles: [1, 2],
      children: [
        {
          title: 'Hesap Hareketleri',
          key: 'account-activities',
          icon: 'fe fe-dollar-sign',
          url: '/account-activities',
          roles: [1, 2],
        },
        {
          title: 'Avans',
          key: 'companies',
          icon: 'fe fe-dollar-sign',
          url: '/advance-payments',
          roles: [1, 2],
        },
        {
          title: 'Maaş Ödemeleri',
          key: 'employees',
          icon: 'fe fe-dollar-sign',
          url: '/employees',
          roles: [1],
        },
      ],
    },
    {
      title: 'Tanımlar',
      key: 'settings',
      icon: 'fe fe-settings',
      url: '/settings',
      roles: [1],
      children: [
        {
          title: 'Şirketler',
          key: 'companies',
          icon: 'fe fe-list',
          url: '/companies',
          roles: [1],
        },
        {
          title: 'Şubeler',
          key: 'branches',
          icon: 'fe fe-list',
          url: '/branches',
          roles: [1],
        },
        {
          title: 'Paketler',
          key: 'packages',
          icon: 'fe fe-list',
          url: '/packages',
          roles: [1],
        },
        {
          title: 'Ödüller',
          key: 'awards',
          icon: 'fe fe-list',
          url: '/awards',
          roles: [1],
        },
      ],
    },
  ];
}
