import React, { useCallback } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import store from 'store';

import UserMenu from './UserMenu';
import { getCompanies } from 'services';
import { history } from 'index';

import style from './style.module.scss';
import api from 'services/api';
import { useEffect } from 'react';

const TopBar = () => {
  const dispatch = useDispatch();
  const { companyId } = useSelector(store => store.user);

  const { data: companies, isLoading: companiesLoading, refetch } = useQuery(
    'companies',
    () => getCompanies(),
    {
      onSuccess: data => {
        dispatch({
          type: 'user/SET_STATE',
          payload: {
            companies: data,
          },
        });
      },
      enabled: false,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (companyId) {
      api.setCompany(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if (!companyId) {
      api.setCompany(companies?.[0].id);
    }
  }, [companies]);

  const onChangeCurrenctCompany = useCallback(
    id => {
      dispatch({
        type: 'user/SET_STATE',
        payload: {
          companyId: id,
        },
      });

      history.push('/');
    },
    [companyId],
  );

  return (
    <div className={style.topbar}>
      <div className="mr-auto" />
      <Select
        loading={companiesLoading}
        onChange={onChangeCurrenctCompany}
        defaultValue={companyId}
        style={{ width: 150 }}
      >
        {companies?.map(company => (
          <Select.Option key={company.id} value={company.id}>
            {company.title}
          </Select.Option>
        ))}
      </Select>
      <div className="ml-4">
        <UserMenu />
      </div>
    </div>
  );
};

export default TopBar;
