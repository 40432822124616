import api from './api';

const prefixUrl = 'companies';

export const getCompanies = () => {
  return api.getRequest(prefixUrl).then(res => res.data);
};

export const getCompanyById = id => {
  return api.getRequest(prefixUrl + '/' + id).then(res => res.data);
};

export const addCompany = data => {
  return api.postRequest(prefixUrl, data);
};

export const updateCompany = (id, data) => {
  return api.patchRequest(prefixUrl + '/' + id, data);
};

export const deleteCompany = id => {
  return api.deleteRequest(prefixUrl + '/' + id);
};
