import React, { useState } from 'react';
import { connect } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Avatar, Badge } from 'antd';
import styles from './style.module.scss';

const mapStateToProps = ({ user }) => ({ user });

const ProfileMenu = ({ dispatch, user }) => {
  const [count, setCount] = useState(7);

  const logout = e => {
    e.preventDefault();
    dispatch({
      type: 'user/LOGOUT',
    });
  };

  const addCount = () => {
    setCount(count + 1);
  };

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>Merhaba, {user.name || 'Bilinmiyor'}</strong>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          Çıkış Yap
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
      <div className={styles.dropdown}>
        <Badge>
          <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
        </Badge>
      </div>
    </Dropdown>
  );
};

export default connect(mapStateToProps)(ProfileMenu);
