import api from './api';

const prefixUrl = 'branches';

export const getBranches = () => {
  return api.getRequest(prefixUrl).then(res => res.data);
};

export const getBranchById = id => {
  return api.getRequest(prefixUrl + '/' + id).then(res => res.data);
};

export const addBranch = data => {
  return api.postRequest(prefixUrl, data);
};

export const updateBranch = (id, data) => {
  return api.patchRequest(prefixUrl + '/' + id, data);
};

export const deleteBranch = id => {
  return api.deleteRequest(prefixUrl + '/' + id);
};
