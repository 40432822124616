import api from './api';

const prefixUrl = 'visits';

export const getVisits = () => {
  return api.getRequest(prefixUrl).then(res => res.data);
};

export const getVisitById = id => {
  return api.getRequest(prefixUrl + '/' + id).then(res => res.data);
};

export const addVisit = data => {
  return api.postRequest(prefixUrl, data);
};

export const updateVisit = (id, data) => {
  return api.patchRequest(prefixUrl + '/' + id, data);
};

export const deleteVisit = id => {
  return api.deleteRequest(prefixUrl + '/' + id);
};
