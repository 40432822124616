import api from './api';

const prefixUrl = 'employees';

export const getEmployees = params => {
  let queryParams = '';
  if (params) {
    const handledParams = Object.fromEntries(
      Object.entries(params).filter(([_, item]) => Boolean(item)),
    );
    queryParams = new URLSearchParams(handledParams);
    queryParams = '?' + queryParams;
  }
  return api.getRequest(prefixUrl + queryParams).then(res => res.data);
};

export const getEmployeesWithFilter = data => {
  return api.postRequest(prefixUrl + '/search', data).then(res => res.data);
};

export const getEmployeeById = id => {
  return api.getRequest(prefixUrl + '/' + id).then(res => res.data);
};

export const getEmployeeLogs = id => {
  return api.getRequest(prefixUrl + '/' + id + '/detail').then(res => res.data);
};

export const addEmployee = data => {
  return api.postRequest(prefixUrl, data);
};

export const updateEmployee = (id, data) => {
  return api.patchRequest(prefixUrl + '/' + id, data);
};

export const deleteEmployee = id => {
  return api.deleteRequest(prefixUrl + '/' + id);
};

export const updateGoals = data => {
  return api.postRequest(prefixUrl + '/goals', data);
};

export const getMyGoal = () => {
  return api.getRequest(prefixUrl + '/goals').then(res => res.data);
};

export const getFired = (id, data) => {
  return api.postRequest(prefixUrl + '/' + id + '/get-fired', data);
};

export const getBackToWork = (id, data) => {
  return api.postRequest(prefixUrl + '/' + id + '/get-back-to-work', data);
};

export const getPuantaj = data => {
  return api.postRequest(prefixUrl + '/puantaj/get', data);
};

export const savePuantaj = data => {
  return api.postRequest(prefixUrl + '/puantaj', data);
};

export const saveMyPuantaj = data => {
  return api.postRequest(prefixUrl + '/puantaj/me', data);
};

export const getMyPuantaj = () => {
  return api.getRequest(prefixUrl + '/puantaj/me').then(res => res.data);
};