import axios from 'axios'
import store from 'store'

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // withCredentials: true
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  if (typeof error.response !== "undefined") {
    if (error.response.status === 401) {
      store.remove("accessToken");
      return {
        data: {
          success: false,
          message: "Oturum sonlandı",
        },
      };
    }
    return {
      data: {
        success: false,
        message: error?.response?.data?.message,
      },
    };
  }
  return {
    data: {
      success: false,
      message: error?.message,
    },
  };
})

export default apiClient
