import api from './api';

const prefixUrl = 'packages';

export const getPackages = () => {
  return api.getRequest(prefixUrl).then(res => res.data);
};

export const getPackageById = id => {
  return api.getRequest(prefixUrl + '/' + id).then(res => res.data);
};

export const addPackage = data => {
  return api.postRequest(prefixUrl, data);
};

export const updatePackage = (id, data) => {
  return api.patchRequest(prefixUrl + '/' + id, data);
};

export const deletePackage = id => {
  return api.deleteRequest(prefixUrl + '/' + id);
};
