import store from 'store';
import actions from './actions';

const initialState = {
  id: '',
  namesurname: '',
  name: '',
  role: 0,
  email: '',
  avatar: '',
  authorized: process.env.REACT_APP_AUTHENTICATED || false, // false is default value
  loading: false,
  companyId: store.get('Company'),
  companies: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
