import apiClient from 'services/axios'
import { takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
//eslint-disable-next-line
import { history } from '..'

async function getExam(id) {
  return apiClient
    .get(`/exams/${id}`)
    .then(response => {
      return response
    })
    .catch(err => console.log(err))
}

async function getCheck(id) {
  return apiClient
    .get(`/exams/${id}/check`)
    .then(response => {
      return response
    })
    .catch(err => console.log(err))
}

async function finishExam() {
  return apiClient
    .get(`/exams/finish`)
    .then(response => {
      return response
    })
    .catch(err => console.log(err))
}

export function* GET_EXAM({ payload }) {
  yield put({
    type: 'exam/RESET_EXAM',
  })
  yield put({
    type: actions.START_LOADING,
  })
  yield put({
    type: 'exam/RESET_CHECK',
  })
  try {
    const { data } = yield call(getExam, payload)
    if (data.success) {
      yield put({
        type: 'exam/SET_EXAM',
        payload: data.exam,
      })
      yield put({
        type: 'exam/GET_CHECK',
        payload,
      })
    } else {
      yield put({
        type: 'exam/RESET_EXAM',
      })
      notification.warning({
        message: 'İşlem Başarısız',
        description: data.message,
      })
      history.push('/examples')
    }
  } catch (error) {
    yield put({
      type: 'exam/RESET_EXAM',
    })
    notification.warning({
      message: 'İşlem Başarısız',
      description: error.message,
    })
  }
  yield put({
    type: actions.STOP_LOADING,
  })
}

export function* FINISH_EXAM({ payload }) {
  yield put({
    type: actions.START_LOADING,
  })
  localStorage.removeItem("exam")
  try {
    const { data } = yield call(finishExam)
    if (data.success) {
      yield put({
        type: actions.GET_CHECK,
        payload: payload,
      })

      history.push('/examples')
    } else {
      notification.warning({
        message: 'İşlem Başarısız',
        description: data.message,
      })
    }
  } catch (error) {
    notification.warning({
      message: 'İşlem Başarısız',
      description: error.message,
    })
  }
  yield put({
    type: actions.STOP_LOADING,
  })
}

export function* GET_CHECK({ payload }) {
  try {
    const { data } = yield call(getCheck, payload)
    yield put({
      type: 'exam/SET_CHECK',
      payload: data,
    })
    if (data.error) {
      notification.warning({
        message: 'İşlem Başarısız',
        description: data.message,
      })
    }
  } catch (error) {
    yield put({
      type: 'exam/RESET_CHECK',
    })
    notification.warning({
      message: 'İşlem Başarısız',
      description: error.message,
    })
  }
}

export default function* rootSaga() {
  yield takeEvery(actions.GET_EXAM, GET_EXAM)
  yield takeEvery(actions.GET_CHECK, GET_CHECK)
  yield takeLatest(actions.FINISH_EXAM, FINISH_EXAM)
}
