import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
// eslint-disable-next-line import/no-cycle
import settings from './settings/sagas'
// eslint-disable-next-line import/no-cycle
import exam from './exam/sagas'

export default function* rootSaga() {
  yield all([user(), menu(), settings(), exam()])
}
