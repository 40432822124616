import axios from 'axios';
import store from 'store';

export class Api {
  constructor() {
    this.company = null;
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });

    this.axios.interceptors.request.use(request => {
      request.headers.Company = this.company;

      const accessToken = store.get('accessToken');
      if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`;
        request.headers.AccessToken = accessToken;
      }
      return request;
    });

    this.axios.interceptors.response.use(undefined, error => {
      if (typeof error.response !== 'undefined') {
        if (error.response.status === 401) {
          store.remove('accessToken');
          return {
            data: {
              success: false,
              message: 'Oturum sonlandı',
            },
          };
        }
        return {
          data: {
            success: false,
            message: error?.response?.data?.message,
          },
        };
      }
      return {
        data: {
          success: false,
          message: error?.message,
        },
      };
    });
  }

  setCompany(id) {
    store.set('Company', id);
    this.company = id;
  }

  getRequest(url) {
    return this.axios.get(url);
  }

  postRequest(url, data) {
    return this.axios.post(url, data);
  }

  patchRequest(url, data) {
    return this.axios.patch(url, data);
  }

  deleteRequest(url) {
    return this.axios.delete(url);
  }
}

export default new Api();
