import api from './api';

const prefixUrl = 'awards';

export const getAwards = () => {
  return api.getRequest(prefixUrl).then(res => res.data);
};

export const getAwardById = id => {
  return api.getRequest(prefixUrl + '/' + id).then(res => res.data);
};

export const addAward = data => {
  return api.postRequest(prefixUrl, data);
};

export const updateAward = (id, data) => {
  return api.patchRequest(prefixUrl + '/' + id, data);
};

export const deleteAward = id => {
  return api.deleteRequest(prefixUrl + '/' + id);
};
