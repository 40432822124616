import actions from './actions'

const initialState = {
  exam: {},
  answers: [],
  loading: false,
  check: {
    error: true,
    exam: false,
    video: false,
    needExam: false,
    needVideo: false,
    videoApproved: null,
    done:false
  },
}

export default function examReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_EXAM:
      return { ...state, exam: action.payload }
    case actions.RESET_EXAM:
      return { ...initialState }
    case actions.START_LOADING:
      return { ...state, loading: true }
    case actions.STOP_LOADING:
      return { ...state, loading: false }
    case actions.SET_ANSWERS:
      return { ...state, answers: action.payload }
    case actions.SET_CHECK:
      return { ...state, check: {...action.payload} }
    case actions.RESET_CHECK:
      return { ...state, check: {...initialState.check} }
    default:
      return state
  }
}
