import React, { lazy, Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { connect } from 'react-redux';

import Layout from 'layouts';

const routes = [
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard/alpha')),
    exact: true,
    roles: [1, 2],
  },
  {
    path: '/companies',
    Component: lazy(() => import('pages/company/List')),
    exact: true,
    roles: [1],
  },
  {
    path: '/companies/add',
    Component: lazy(() => import('pages/company/Add')),
    exact: true,
    roles: [1],
  },
  {
    path: '/companies/edit/:id',
    Component: lazy(() => import('pages/company/Edit')),
    exact: true,
    roles: [1],
  },
  {
    path: '/teams',
    Component: lazy(() => import('pages/team/List')),
    exact: true,
    roles: [1, 2],
  },
  {
    path: '/teams/add',
    Component: lazy(() => import('pages/team/Add')),
    exact: true,
    roles: [1],
  },
  {
    path: '/teams/edit/:id',
    Component: lazy(() => import('pages/team/Edit')),
    exact: true,
    roles: [1],
  },
  {
    path: '/awards',
    Component: lazy(() => import('pages/award/List')),
    exact: true,
    roles: [1],
  },
  {
    path: '/awards/add',
    Component: lazy(() => import('pages/award/Add')),
    exact: true,
    roles: [1],
  },
  {
    path: '/awards/edit/:id',
    Component: lazy(() => import('pages/award/Edit')),
    exact: true,
    roles: [1],
  },
  {
    path: '/branches',
    Component: lazy(() => import('pages/branch/List')),
    exact: true,
    roles: [1],
  },
  {
    path: '/branches/add',
    Component: lazy(() => import('pages/branch/Add')),
    exact: true,
    roles: [1],
  },
  {
    path: '/branches/edit/:id',
    Component: lazy(() => import('pages/branch/Edit')),
    exact: true,
    roles: [1],
  },
  {
    path: '/packages',
    Component: lazy(() => import('pages/packages/List')),
    exact: true,
    roles: [1],
  },
  {
    path: '/packages/add',
    Component: lazy(() => import('pages/packages/Add')),
    exact: true,
    roles: [1],
  },
  {
    path: '/packages/edit/:id',
    Component: lazy(() => import('pages/packages/Edit')),
    exact: true,
    roles: [1],
  },
  {
    path: '/customers',
    Component: lazy(() => import('pages/customers/List')),
    exact: true,
    roles: [1, 2],
  },
  {
    path: '/customers/add',
    Component: lazy(() => import('pages/customers/Add')),
    exact: true,
    roles: [1, 2],
  },
  {
    path: '/customers/edit/:id',
    Component: lazy(() => import('pages/customers/Edit')),
    exact: true,
    roles: [1],
  },
  {
    path: '/customers/:id',
    Component: lazy(() => import('pages/customers/Detail')),
    exact: true,
    roles: [1],
  },
  {
    path: '/advance-payments',
    Component: lazy(() => import('pages/advancePayment/List')),
    exact: true,
    roles: [1, 2],
  },
  {
    path: '/advance-payments/add',
    Component: lazy(() => import('pages/advancePayment/Add')),
    exact: true,
    roles: [1],
  },
  {
    path: '/employees',
    Component: lazy(() => import('pages/employees')),
    exact: true,
    roles: [1, 2],
  },
  {
    path: '/employees/add',
    Component: lazy(() => import('pages/employees/Add')),
    exact: true,
    roles: [1],
  },
  {
    path: '/employees/edit/:id',
    Component: lazy(() => import('pages/employees/Edit')),
    exact: true,
    roles: [1],
  },
  {
    path: '/employees/:id',
    Component: lazy(() => import('pages/employees/Detail')),
    exact: true,
    roles: [1, 2],
  },
  {
    path: '/employees/:id',
    Component: lazy(() => import('pages/employees/Detail')),
    exact: true,
    roles: [1],
  },
  {
    path: '/visits',
    Component: lazy(() => import('pages/visits/List')),
    exact: true,
    roles: [1, 2],
  },
  {
    path: '/visits/add',
    Component: lazy(() => import('pages/visits/Add')),
    exact: true,
    roles: [1, 2],
  },
  {
    path: '/account-activities',
    Component: lazy(() => import('pages/accountActivity/List')),
    exact: true,
    roles: [1, 2],
  },
  {
    path: '/waiting-sales',
    Component: lazy(() => import('pages/sale/WaitApprove')),
    exact: true,
    roles: [1, 2],
  },
  {
    path: '/sales',
    Component: lazy(() => import('pages/sale/List')),
    exact: true,
    roles: [1, 2],
  },
  {
    path: '/goals',
    Component: lazy(() => import('pages/goal')),
    exact: true,
    roles: [1],
  },
  {
    path: '/appointments',
    Component: lazy(() => import('pages/appointment/List')),
    exact: true,
    roles: [1, 2],
  },
  {
    path: '/appointments/add',
    Component: lazy(() => import('pages/appointment/Add')),
    exact: true,
    roles: [1, 2],
  },
  {
    path: '/appointments/edit/:id',
    Component: lazy(() => import('pages/appointment/Edit')),
    exact: true,
    roles: [1, 2],
  },
  {
    path: '/puantaj',
    Component: lazy(() => import('pages/puantaj')),
    exact: true,
    roles: [1],
  },
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login/student')),
    exact: true,
  },
  {
    path: '/auth/admin/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
];

const mapStateToProps = ({ settings, user }) => ({
  routerAnimation: settings.routerAnimation,
  role: user.role,
});

const Router = ({ history, routerAnimation, role }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state;
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    {routes
                      .filter(
                        s => typeof s.roles === 'undefined' || (role && s.roles.includes(role)),
                      )
                      .map(({ path, Component, exact }) => (
                        <Route
                          path={path}
                          key={path}
                          exact={exact}
                          render={() => {
                            return (
                              <div className={routerAnimation}>
                                <Suspense fallback={null}>
                                  <Component />
                                </Suspense>
                              </div>
                            );
                          }}
                        />
                      ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            );
          }}
        />
      </Layout>
    </ConnectedRouter>
  );
};

export default connect(mapStateToProps)(Router);
