import api from './api';

const prefixUrl = 'customers';

export const getCustomers = () => {
  return api.getRequest(prefixUrl).then(res => res.data);
};

export const getCustomerLogs = id => {
  return api.getRequest(prefixUrl + '/' + id + '/detail').then(res => res.data);
};

export const getCustomerById = id => {
  return api.getRequest(prefixUrl + '/' + id).then(res => res.data);
};

export const addCustomer = data => {
  return api.postRequest(prefixUrl, data);
};

export const updateCustomer = (id, data) => {
  return api.patchRequest(prefixUrl + '/' + id, data);
};

export const deleteCustomer = id => {
  return api.deleteRequest(prefixUrl + '/' + id);
};
