import apiClient from 'services/axios';
import store from 'store';

export async function login(phone, password, userType) {
  return apiClient
    .post('/auth/login', {
      phone,
      password,
      userType,
    })
    .then(response => {
      if (response) {
        const { token } = response.data;
        if (token) {
          store.set('accessToken', token);
        }
      }
      return response;
    })
    .catch(err => console.log(err));
}

export async function currentAccount() {
  return apiClient
    .get('/user/account')
    .then(response => {
      if (!response?.data) {
        throw new Error(response?.data?.message);
      }
      if (response) {
        if (response?.data?.token) {
          store.set('accessToken', response?.data?.token);
        }
        return response.data;
      }
      return false;
    })
    .catch(err => {
      throw new Error(err);
    });
}

export async function logout() {
  return apiClient
    .get('/auth/logout')
    .then(() => {
      store.remove('accessToken');
      return true;
    })
    .catch(err => console.log(err));
}
