import api from './api';

const prefixUrl = 'teams';

export const getTeams = () => {
  return api.getRequest(prefixUrl).then(res => res.data);
};

export const getTeamById = id => {
  return api.getRequest(prefixUrl + '/' + id).then(res => res.data);
};

export const addTeam = data => {
  return api.postRequest(prefixUrl, data);
};

export const updateTeam = (id, data) => {
  return api.patchRequest(prefixUrl + '/' + id, data);
};

export const deleteTeam = id => {
  return api.deleteRequest(prefixUrl + '/' + id);
};

export const getAvailableMembers = teamid => {
  return api.getRequest(prefixUrl + '/available/' + teamid).then(res => res.data);
};
