const actions = {
  SET_EXAM: 'exam/SET_EXAM',
  GET_EXAM: 'exam/GET_EXAM',
  START_LOADING: 'exam/START_LOADING',
  STOP_LOADING: 'exam/STOP_LOADING',
  RESET_EXAM: 'exam/RESET_EXAM',
  SET_ANSWERS: 'exam/SET_ANSWERS',
  SET_CHECK: 'exam/SET_CHECK',
  GET_CHECK: 'exam/GET_CHECK',
  RESET_CHECK: 'exam/RESET_CHECK',
  FINISH_EXAM: 'exam/FINISH_EXAM',
}

export default actions
