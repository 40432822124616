import 'antd/lib/style/index.less'; // antd core styles
import './components/kit/vendors/antd/themes/default.less'; // default theme antd components
import './components/kit/vendors/antd/themes/dark.less'; // dark theme antd components
import './global.scss'; // app & third-party component styles

import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PersistGate } from 'redux-persist/integration/react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Router from './router';
import * as serviceWorker from './serviceWorker';
import { store, history, persistor } from './redux';
import { ConfigProvider } from 'antd';
import trTR from 'antd/es/locale/tr_TR';

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ConfigProvider locale={trTR}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history} />
        </PersistGate>
      </Provider>
    </ConfigProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
export { store, history };
